import { trigger } from '@/lib/utils'

export const mutations = {
  bootstrap (state) {
    state.isBootstrapped = true
  },
  setLoading (state, isLoading) {
    state.loading = isLoading
  },
  refreshCart (state, data) {
    state.loading = false

    if (data.errors) {
      state.app.cart.errors = data.errors
    } else {
      state.app.cart = data
      trigger('update-gwp', document.body)
    }
  },
  toggleMiniCart (state) {
    state.app.isMiniCartOpen = !state.app.isMiniCartOpen
  },
  setRemovedItem (state, item) {
    state.removedItem = item
  },
  updateGiftMessage (state, message = null) {
    state.app.cart.note = message
  },
  giftMessageState (state, updated = false) {
    state.giftMessageEdited = updated
  },
  setRecommendedVariants (state, recommendedVariants) {
    state.recommendedVariants = recommendedVariants
  }
}
